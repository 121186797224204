
.footer-container>div>img,
.footer-container>div>div>img{
    height: 3rem;
}

.footer{
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    
}
.social-links{
    display: flex;
    gap: 2rem;
}
.logo-f{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}